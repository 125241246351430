<template>
    <v-data-table
        :headers="headers"
        :items="entities"
        :server-items-length="entitiesCount"
        :items-per-page="itemsPerPage"
        :footer-props="{ 'itemsPerPageOptions': [50, 100, 200, -1] }"
        :loading="loading"
        @pagination="paginate"
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\trips\\list.7453') }}</v-toolbar-title>
                <v-spacer />
                <!-- <v-btn color="primary" @click="create">
                    <v-icon>mdi-plus</v-icon>{{ $t('src\\views\\trips\\list.2654') }}</v-btn> -->
            </v-toolbar>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.user`]="{ item }">
            {{ item.user.firstname }} {{ item.user.lastname }}
        </template>
        <template v-slot:[`item.type`]="{ item }">
            {{ $t(TRIP_TYPE_TITLE[item.type]) }}
        </template>
        <template v-slot:[`item.sorting`]="{ item }">
            <v-row class="ma-0 pa-0">
                <v-col cols="9" class="pa-0">
                    <v-text-field
                        v-model="item.sorting"
                        class="pa-0 ma-0"
                        hide-details
                    />
                </v-col>
                <v-col v-if="user.adminAccess && user.adminAccess.trips >= ACCESS.WRITE" cols="3" class="pa-0 d-flex justify-center align-center">
                    <v-icon 
                        small
                        color="primary" 
                        @click="setSorting(item._id, item.sorting)"
                    >mdi-check</v-icon>
                </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.status`]="{ item }">
            {{ $t(TRIP_STATUS_TITLE[item.status]) }}
            <v-menu v-if="user.adminAccess && user.adminAccess.trips >= ACCESS.WRITE">
                <template v-slot:activator="{ attrs, on }">
                    <v-icon
                        color="primary"
                        small
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                    >mdi-pencil</v-icon>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(status, index) in TRIP_STATUS_TITLE"
                        :key="index"
                        @click="setStatus(item._id, index)"
                    >
                        <v-list-item-title>{{ $t(status) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
            <v-icon color="primary" @click="edit(item._id)">mdi-pencil</v-icon>
        </template>
    </v-data-table>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { TRIP_STATUS, TRIP_STATUS_TITLE, TRIP_TYPE_TITLE } from '@/vars';
    import { ACCESS } from '@/vars';

    export default {
        name: 'TripsList',
        components: {
        },
        data: () => ({
            ACCESS,
            page: 1,
            itemsPerPage: 10,
            loading: true,
            TRIP_STATUS,
            TRIP_STATUS_TITLE,
            TRIP_TYPE_TITLE,
            headers: [],
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('trips', ['entities', 'entitiesCount']),
        },
        async mounted() {
            await store.dispatch('auth/fetch');
            this.headers = [
                { text: this.$t('src\\views\\trips\\list.createdAt'), value: 'createdAt' },
                { text: this.$t('src\\views\\trips\\list.sorting'), value: 'sorting' },
                { text: this.$t('src\\views\\trips\\list.type'), value: 'type' },
                { text: this.$t('src\\views\\trips\\list.name'), value: 'name' },
                { text: this.$t('src\\views\\trips\\list.code'), value: 'code' },
                { text: this.$t('src\\views\\trips\\list.user'), value: 'user' },
                { text: this.$t('src\\views\\trips\\list.status'), value: 'status' }
            ];
            if(this.user && this.user.adminAccess && this.user.adminAccess.trips >= ACCESS.WRITE) {
                this.headers.push({ text: '', align: 'right', value: 'edit' })
            }
        },
        methods: {
            async getTrips() {
                this.loading = true;
                await store.dispatch('trips/fetch', { page: this.page, itemsPerPage: this.itemsPerPage });
                this.loading = false;
            },
            async paginate({ page, itemsPerPage }) {
                this.page = page;
                this.itemsPerPage = itemsPerPage;
                await this.getTrips();
            },
            async create() {
                await this.$router.push({ name: 'trips-create' })
            },
            async edit(id) {
                await this.$router.push({ name: 'trips-edit', params: { id } })
            },
            async setStatus(id, status) {
                this.loading = true;
                await store.dispatch('trips/setStatus', { id, status });
                await this.getTrips();
            },
            async setSorting(id, sorting) {
                this.loading = true;
                await store.dispatch('trips/setSorting', { id, sorting });
                await this.getTrips();
            }
        }
    }
</script>
